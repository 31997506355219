body {
  margin: 0;
  /* font-family: 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif,-apple-system, BlinkMacSystemFont,;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: 'San Francisco Text';
}

@font-face {
  font-family: "San Francisco Text";
  src: url(./fonts/gilroy/SVN-Gilroy\ Regular.otf) format("truetype"); 
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: "San Francisco Text";
  src: url(./fonts/gilroy/SVN-Gilroy\ Italic.otf) format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "San Francisco Text";
  src: url(./fonts/gilroy/SVN-Gilroy\ Bold\ Italic.otf) format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "San Francisco Text";
  src: url(./fonts/gilroy/SVN-Gilroy\ Bold.otf) format("truetype");
  font-weight: bold;
  font-style: normal;
}
