.dropzone {
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  background-color: #F8F8FF;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  border-radius: 8px;
  border: 1.5px dashed #AEB7FF;
  text-align: center;
  cursor: pointer;
}
.textCustom {
  text-align: center;
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.15px;
  color: #5678ff;
}
.textCustomGray {
  text-align: center;
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: rgba(9, 30, 66, 0.6);
}
.textSuggest {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #7B8091;
}